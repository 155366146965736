@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap);
/*body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}*/

:root {
  --primary-white: #ffffff;
  --primary-light-grey: #f4f4f4;
  --primary-grey: #ccd1d9;
  --primary-dark-grey: #666666;
  --primary-orange: #f9ab00;
  --primary-dark: rgb(52, 58, 64);
  --primary-red: #ef4035;
  --primary-hover-red: #f33501;
  --primary-black: #000000;
  --primary-transparent-black: rgb(0,0,0,0.7);
  --primary-green: green;
  --primary-light-blue:#72bfbe;
  /* --primary-blue:#24b9b7; */
  --primary-blue:#0abdba;


}

.body{
  font-family: "Roboto Condensed", sans-serif !important;
  
}

/***************************************Navbar*************************************************/

nav {
  z-index: 2;

}

.logo{
   width: 16.5rem;
}

/**----------------------------------NAV LINKS-------------------------------------------------*/

nav a.nav-link {
  font-size: 0.8rem ;
  letter-spacing: 0.0375rem;
  cursor: pointer;
}

nav ul li{
  text-transform: uppercase ;
}

.navbar-nav > li > a {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
  color: #ffffff !important;
  color: var(--primary-white) !important;
  border-top: 0.1875rem solid rgb(52, 58, 64);
  border-top: 0.1875rem solid var(--primary-dark) ;
}

.navbar-brand {
  max-width:17.25rem;
  padding:0 !important;
  height: 6rem !important;
}

/*byLH---------------------*/
.navbar-brand img{
  /* width:100%; */
  height: 100%;
}

.logo-name {
    color: #72bfbe;
    color: var(--primary-light-blue);
    font-weight: lighter;
    font-size: 30px;
}

.collapse{
  display:flex;
  justify-content: space-around;
}

@media(max-width: 990px) {
  .collapse{
    display:inline-block;
  }
 
}

/* end of LH changes -------------------*/

@media(max-width: 768px) {
  .navbar-brand{
    width: 7rem ;
  }
}

.navbar-nav > li > a.active, .navbar-nav > li > a:focus {
  /* border-top: 0.1875rem solid var(--primary-orange); */
  border-top: 0.1875rem solid #0abdba;
  border-top: 0.1875rem solid var(--primary-blue);

}

.navbar-nav > li > a:hover{
  /* border-top: 0.1875rem solid var(--primary-orange); */
  border-top: 0.1875rem solid #0abdba;
  border-top: 0.1875rem solid var(--primary-blue);

}

/***************************************Header*************************************************/

.header-wraper{
  position: relative;
  /* background: url(wall-and-laptop-background.jpg); */
  background: url(/static/media/portfolio-background-3.1ff73325.png);
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.main-info{
  display:flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "Roboto Condensed", sans-serif;
  z-index: 1;
}

.main-info h1{
  /* color: var(--primary-orange); */
  color: #0abdba;
  color: var(--primary-blue);
  text-transform: uppercase;
}


/***************************************Typed Text*************************************************/
.typed-text {
  font-size:2rem;
  color: #ffffff;
  color: var(--primary-white)
}

@media(max-width:768px) {
    .main-info h1{
      font-size: 1.7rem !important;
    }
  .typed-text {
    font-size:1.3rem !important;
  }
}

@media(max-height:376px) {
  .main-info h1{
    margin-top: auto;
  }
}


/***************************************Header Main offer button***********************************/

.btn-main-offer{
  border: 1px solid #ef4035;
  border: 1px solid var(--primary-red);
  text-transform: uppercase;
  border-radius: 0;
  padding: 0.625rem 0.75rem 0.75rem 0.75rem;
  color: #ffffff;
  color: var(--primary-white);
  margin:2rem;
  text-decoration: none;
}

.btn-main-offer:hover{
  text-decoration: none ;
  background-color: #f33501;
  background-color: var(--primary-hover-red) ;
  color: #ffffff;
  color: var(--primary-white);
  transition: .2s ease-in-out;

}

/***************************************Particles Js***********************************************/

.tsparticles-canvas-el{
  position: absolute !important;
  height: 100vh !important;
  width: 100vw !important;
  z-index: 1;
}



@media(max-width:768px){
  .tsparticles-canvas-el {
    display:none;
  }
}

@media(max-width:768px){
  .tsparticles-canvas-el {
    display:none;
  }
}



/*******************************************About Me*********************************************/

.photo-wrap{
  display:flex;
  justify-content: center;
  align-items: center;
}

.profile-img{
  width: 16rem;
  border-radius: 50%;
  padding: .3rem;
  /* border: 1px solid var(--primary-orange); */
  border: 1px solid #0abdba;
  border: 1px solid var(--primary-blue);

}

.about-heading{
  /* color: var(--primary-orange); */
  color: #0abdba;
  color: var(--primary-blue);

  text-transform: uppercase;
}

@media(max-width:468px){
  .profile-img{
    width: 10rem;
  }

}

/*******************************************Services*********************************************/

.services {
  text-align: center;
  padding-bottom: 2rem;
  background: #f4f4f4;
  background: var(--primary-light-grey);
}

.services h1 {
  color: rgb(52, 58, 64);
  color: var(--primary-dark);
  text-transform: uppercase;
}

.services .circle {
  position: relative;
  margin: 0.375rem auto;
  /* background: var(--primary-orange); */
  background: #0abdba;
  background: var(--primary-blue);
  border-radius: 50%;
  width: 3.125rem;
  height: 3.125rem;

}

.services .icon{
  font-size: 1.4rem;
  color: #ffffff;
  color: var(--primary-white);
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);

}

.services .box{
  height: 16rem;
  /* border-bottom: 0.3125rem solid var(--primary-orange); */
  border-bottom: 0.3125rem solid #0abdba;
  border-bottom: 0.3125rem solid var(--primary-blue);
  padding: 0.625rem;
  margin-bottom: 1.875rem;
  background: #ffffff;
  background: var(--primary-white);
  transition: 0.3s ease-in;
}

.services .box:hover{
  /* background: var(--primary-orange); */
  background: #0abdba;
  background: var(--primary-blue);
  border-bottom: 0.3125rem solid rgb(52, 58, 64);
  border-bottom: 0.3125rem solid var(--primary-dark);
}

.services .box:hover .icon{
  color: rgb(52, 58, 64);
  color: var(--primary-dark);
}

.services .box:hover .circle{
  background: #ffffff;
  background: var(--primary-white);
}

.services .box:hover p{
  color: #ffffff;
  color: var(--primary-white);
}

/*******************************************Experience*********************************************/

.experience{
  padding:0.7rem;
}

.experience-wrapper {
  position:relative;
  padding: 3.125rem 0;
  overflow:hidden;
}

.experience h1 {
  text-transform: uppercase;
  /* color: var(--primary-orange); */
  color: #0abdba;
  color: var(--primary-blue);

}

.experience-wrapper::before{
  position: absolute;
  content: "";
  top:0;
  left:50%;
  margin-left: -0.0625rem;
  width: 0.125rem;
  height: 100%;
  background: #ccd1d9;
  background: var(--primary-grey);
  z-index: 1;
}

.timeline-block {
  width: calc(50% + 0.5rem);
  display:flex;
  justify-content: space-between;
  clear: both;
}

.timeline-block-right{
  float: right;
}

.timeline-block-left {
  float: left;
  direction: rtl;
}

.marker {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 0.3125rem solid rgb(52, 58, 64);
  border: 0.3125rem solid var(--primary-dark);
  /* background: var(--primary-orange); */
  background: #0abdba;
  background: var(--primary-blue);
  margin-top: 0.625rem;
  z-index: 3;
}

.timeline-content {
  width:95%;
  padding: 0 0.9375rem;
  color:#666666;
  color:var(--primary-dark-grey);
  direction: ltr;
}

.timeline-content h3{
  margin-top: 0.3125rem;
  color: #ef4035;
  color: var(--primary-red);
  font-weight: 900;
}

/*---------------------------MEDIA---------------------------*/

@media(max-width:768px){
  
  .experience-container{
    padding-left: 0;
  }
  .experience-wrapper::before{
    left:0.5rem;
    margin-bottom:1.875rem;
  }
  .timeline-block-right{
    float:none;
    width:100%;
  }
  .timeline-block-left{
    float:none;
    direction:ltr;
    width:100%;
  }
}

/*******************************************Portfolio*********************************************/

.portfolio-wrapper {
  background: #f4f4f4;
  background: var(--primary-light-grey);
  padding: 3rem 0;
}

.portfolio-wrapper h1 {
  /* color: var(--primary-orange); */
  color: #0abdba;
  color: var(--primary-blue);

}

.portfolio-image {
  width: 15rem;
  border: 1px solid #f9ab00;
  border: 1px solid var(--primary-orange);
  padding: 0 0.5rem;
}

.portfolio-image-box {
  position: relative;
  margin: 1rem;
}

.image-box-wrapper {
  display:flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

/*******************************************Overflow Box*********************************************/

.overflow {
  position: absolute;
  opacity: 0;
  background: #000000;
  background: var(--primary-black);
  width: 15rem;
  height: 8rem;
  top:0;
  border:1px solid #ef4035;
  border:1px solid var(--primary-red);
  cursor:pointer;
  
}

.portfolio-image-box:hover .overflow {
  opacity:60%;
  transition: 0.3s ease-in-out;
  
}

.portfolio-icon {
  position: absolute;
  opacity:0.2;
  margin-left: -8rem;
  margin-top: 3.6rem;
  color: #f9ab00;
  color: var(--primary-orange);
  font-size: 1.5rem;
}

.portfolio-image-box:hover .portfolio-icon{
  opacity: 70%;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.popup-wrapper {
  margin-top: 7rem;
}


/*******************************************Popup Box*********************************************/

.porfolio-image-popupbox {
  width:45rem;
  padding: 0 0.5rem; 
}

.hyper-link {
  cursor: pointer;
  color: #f9ab00;
  color: var(--primary-orange);
}

.hyper-link:hover {
  color: #ef4035;
  color: var(--primary-red);
}

/*******************************************MEDIA*********************************************/

@media(max-width:768px){
  .porfolio-image-popupbox{
    width:100%;
  }
}

@media(max-height:570px){
  .popup-wrapper{
    height:100% !important;
  }

  .porfolio-image-popupbox{
    width:50%;
  }
}

@media(max-height:280px){
  .popup-wrapper{
    height:90% !important;
  }

  .porfolio-image-popupbox{
    width:30%;
  }
}

/*******************************************Testimonials********************************************/

.testimonials {
  background-image: url(/static/media/testimonials-bg.75612be7.jpg);;
  background-size: cover;;
  background-color: #000000;;
  background-color: var(--primary-black);
  text-align: center;
  padding-bottom: 4rem;
  margin-bottom: 0;
  width:100%
}

.testimonials h1 {
  /* color: var(--primary-orange); */
  color: #0abdba;
  color: var(--primary-blue);
  padding: 3rem 0;
  text-transform: uppercase;
}

.testimonials-content {
  text-align: center;
  max-width: 53.125rem;
  margin:0 auto 2rem auto;
  background-color: rgb(0,0,0,0.7);
  background-color: var(--primary-transparent-black);
}

.myCarousel {
  color:#ffffff;
  color:var(--primary-white);
  background: transparent;
  margin-top:-6%;
  width:54%;
  margin-left:auto;
  margin-right:auto;
  padding-top:6%;
  padding-bottom: 8%;
  padding-left: 5%;
  padding-right: 5%;
  height:17.875rem;
}

.myCarousel h3{
  /* color: var(--primary-orange); */
  color: #0abdba;
  color: var(--primary-blue);
  font-weight: 600;
  text-transform: uppercase;
}

.myCarousel p {
  color: #ccd1d9;
  color: var(--primary-grey);
}

.carousel .slide img {
  width: 10rem !important;
  border-radius: 50%;
}

.carousel .slide {
  background: transparent !important;
  height: 100% !important;
  padding-top: 2rem;
}


.carousel-root {
  margin:auto !important;
  margin-top: 3% !important;
}

/*******************************************Contacts component*************************************/

.contacts {
  background-color: rgb(52, 58, 64);
  background-color: var(--primary-dark);
  padding: 3rem 0;
}

.contacts h1 {
  /* color:var(--primary-orange); */
  color:#0abdba;
  color:var(--primary-blue);

  padding: 1.3rem 0;
  text-transform: uppercase;
}

.contacts p {
  color: #666666;
  color: var(--primary-dark-grey);
  font-size: 1.2rem;
}

/*******************************************INPUTS*******************/

.contacts input, .contacts textarea {
  width: 100%;
  box-sizing: border-box;
  background: none;
  resize: none;
  border: 0;
  border-bottom: 0.125rem solid #666666;
  border-bottom: 0.125rem solid var(--primary-dark-grey);
  border-radius: 0;
  /* color: var(--primary-orange); */
  color: #0abdba;
  color: var(--primary-blue);
  outline: 0 !important;
  box-shadow: none !important;
  margin-bottom: 1rem;
  padding-left: 0 !important;
}

.contacts .container {
  padding: 4rem 0;
}

.contacts input:focus, .contacts textarea:focus {
  background:transparent;
  /* color: var(--primary-orange); */
  color: #0abdba;
  color: var(--primary-blue);
  border-bottom: 0.125rem solid #666666;
  border-bottom: 0.125rem solid var(--primary-dark-grey);
}

.contacts textarea {
  height: 12.2rem !important;
}

.contact-btn {
  margin: 1rem 0 0 0;
  width: 100%;
  background: none;
}

.line{
  position: relative;
  top: -0.8rem;  
  width:0;
  height:0.125rem;
  /* background: var(--primary-orange); */
  background: #0abdba;
  background: var(--primary-blue);
  display:inline-block;
}

input:focus+.line, textarea:focus+.line {
  width:100%;
  transition: 0.5s ease-in-out;
}

input.form-control {
  margin:-0.3rem 0;
}

textarea.form-control {
  margin:-0.3rem 0;
}

.error-message {
  color: #ffffff;
  color: var(--primary-white);
  background: #f33501;
  background: var(--primary-hover-red);
  text-align: left;
}

.success-message {
  font-weight: 900;
  color: green;
  color: var(--primary-green)
}

/*******************************************MEDIA****************************/

@media(max-width: 768px) {

  .contacts {
    overflow-x: hidden;
  }


  .contacts .container {
    padding: 0 1rem;
  }
  
  .contacts p {
    padding: 0 0.4rem;
  }

}

/*******************************************FOOTER****************************/

.footer {
  background: #000000;
  background: var(--primary-black);
  color: #666666;
  color: var(--primary-dark-grey);
  padding: 3rem;
}

.footer a {
  text-decoration: none;
  color: #666666;
  color: var(--primary-dark-grey);
}

.footer a:hover {
  text-decoration: none;
  color: #f9ab00;
  color: var(--primary-orange);
  cursor:pointer;
}

.footer p{
  margin-bottom: 0;
}

.social-icons-box{
  display:flex !important;
  justify-content: center;
}
:root{
  --primary-scarlet:rgb(53, 12, 21);
  --primary-white:#ffffff;
  --primary-dark: rgb(52, 58, 64);
  --primary-light-blue:#72bfbe;
}


.form-signin {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    background:rgb(240, 233, 233);
    background-image: url('https://ik.imagekit.io/bwcdq46tkc8/s4b-consulting/flying-lake-2021_3Ba1RR2H2.jpeg?ik-sdk-version=javascript-1.4.3&updatedAt=1647345250261');
    background-size: cover;
    opacity: 0.8;
    height: 65vh;
  }

  .form-signin:hover{
    opacity:0.95
  }
  
  .form-signin .checkbox {
    font-weight: 400;
  }
  
  .form-signin .form-floating:focus-within {
    z-index: 2;
  }
  
  .form-signin input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    /* width: 400px; */
  }
  
  .form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    /* width: 400px; */
    
  }
  

  .img-logo2 {   
    /*margin:auto;*/
    background-color: rgb(219, 214, 224);
    background-image: url(/static/media/s4b-logo-long.b0bf9b61.png);
    height: 10vh;
    

    /*margin-inline-start: 170px;*/
  }

  .modal-content {
    color: #72bfbe;
    color: var(--primary-light-blue);
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 900;
  }

.headerLogin {
  margin-top: auto;
}

.modal-footer{
  display:flex !important;
  flex-direction: row;
  justify-content: space-between !important;
}

 /*--------------------------------------HOME PAGE ---------------------------*/

 .modal-container{
    height: 20vh !important;
    overflow: hidden;
  }

  .s4b-logo{
    border-radius: 50%;
    height: 4vh;
    margin: 5px;
  }

  .s4b-logo2{
    border-radius: 50%;
    height: 10vh;
    margin: 5px;
  }

  .form-footer{
    display:flex;
    flex-direction: row;
    align-items: center;
  }





  /*--------------------------------------MEDIA---------------------------------------------------------------*/

  /* @media(min-width: 450px){ */
    @media(min-width: 668px){

    
  .form-signin input[type="email"] {
    width: 400px;
  }
  
  .form-signin input[type="password"] {
    width: 400px;  
  }


  }

  @media(max-width: 668px){
    
    .img-logo2 {
      display: none;
    }  

    .form-signin input[type="email"] {
      height: 50px;
      font-size:15px;
    }
    
    .form-signin input[type="password"] {
      height: 50px;  
      font-size:15px;
    }

    .form-signin button[type="submit"]{
      height:40px;
      font-size:15px;
    }

    .headerLogin {
      font-size: 18px;
    }

    }

  @media(max-width:455px){
    .modal-footer{
      flex-direction: column-reverse;
    }
  }    
